import { useRouter } from "next/router";
import PageLayout from "../components/layout/PageLayout/PageLayout";
import RiskModellingLayout from "../components/layout/RiskModellingLayout/RiskModellingLayout";
import RootLayout from "../components/layout/RootLayout/RootLayout";
import ProjectsList, {
  type ProjectSearchQueryChangeHandler,
} from "../components/projects/ProjectsList/ProjectsList";
import {
  getQueryFromProjectSearchQuery,
  pluckProjectSearchQueryFromQuery,
  type ProjectSearchQuery,
} from "../components/projects/ProjectsList/projectSearchQuery";
import AppNavSubheader from "../components/ui/AppNavSubheader/AppNavSubheader";
import HeadTitle from "../components/util/HeadTitle/HeadTitle";
import type { NextPageWithLayout } from "./_app";

const ProjectsPage: NextPageWithLayout = () => {
  const router = useRouter();

  const onSearchQueryChange: ProjectSearchQueryChangeHandler = (
    searchQuery: ProjectSearchQuery,
    { replace } = {},
  ) => {
    const query = getQueryFromProjectSearchQuery(searchQuery);
    const navigate = replace ? router.replace : router.push;

    void navigate({
      pathname: "/",
      query: {
        ...query,
      },
    });
  };

  const searchQuery = pluckProjectSearchQueryFromQuery(router.query);

  return (
    <>
      <HeadTitle>Modelling projects</HeadTitle>
      <PageLayout>
        <ProjectsList
          onSearchQueryChange={onSearchQueryChange}
          searchQuery={searchQuery}
        />
      </PageLayout>
    </>
  );
};

ProjectsPage.getLayout = (page) => {
  return (
    <RootLayout>
      <RiskModellingLayout
        title="Modelling projects"
        subheader={<AppNavSubheader hasSubheaderContent={false} />}
      >
        {page}
      </RiskModellingLayout>
    </RootLayout>
  );
};

export default ProjectsPage;
