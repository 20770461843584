import { AnchorButton } from "@kablamooss/geo-ds-core-components";
import TaskLink from "../../util/TaskLink/TaskLink";

interface ViewProjectLinkButtonProps {
  "data-testid"?: string;
  projectId: string;
}

const ViewProjectLinkButton = ({
  "data-testid": dataTestId,
  projectId,
}: ViewProjectLinkButtonProps) => {
  return (
    <TaskLink href={`/project/${projectId}/scenarios`}>
      <AnchorButton data-testid={dataTestId} variant="secondary">
        Open project
      </AnchorButton>
    </TaskLink>
  );
};

export default ViewProjectLinkButton;
