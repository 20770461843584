import {
  NavSubheader,
  type NavSubheaderProps,
} from "@kablamooss/geo-ds-core-components";
import { styled } from "styled-components";
import useIsOnline from "../../../hooks/useIsOnline";
import getPublicRuntimeConfig from "../../../utils/getPublicRuntimeConfig/getPublicRuntimeConfig";
import HeaderIndicator from "../HeadIndicator/HeaderIndicator";

const StyledContent = styled.div`
  flex: 1;
`;

interface AppNavSubheaderProps extends NavSubheaderProps {
  hasSubheaderContent?: boolean;
}

const AppNavSubheader = ({
  children,
  hasSubheaderContent = true,
  ...props
}: AppNavSubheaderProps) => {
  const isOnline = useIsOnline();

  const { ENVIRONMENT, IGNORE_ENVIRONMENT_INDICATOR } =
    getPublicRuntimeConfig();

  const isNotProd = ENVIRONMENT !== "prod";

  let banner: React.ReactNode;
  if (!isOnline) {
    banner = (
      <HeaderIndicator variant="offline" data-testid="offline-indicator">
        You are currently operating offline.
      </HeaderIndicator>
    );
  } else if (isNotProd && !IGNORE_ENVIRONMENT_INDICATOR) {
    banner = (
      <HeaderIndicator variant="tenant" data-testid="tenant-indicator">
        Currently operating in the {ENVIRONMENT.toUpperCase()} environment
      </HeaderIndicator>
    );
  }

  return (
    <>
      {hasSubheaderContent && (
        <NavSubheader {...props}>
          <StyledContent>{children}</StyledContent>
        </NavSubheader>
      )}
      {banner}
    </>
  );
};

export default AppNavSubheader;
