import {
  NavDrawer,
  NavDrawerMenu,
  NavDrawerMenuItem,
} from "@kablamooss/geo-ds-core-components";
import styled from "styled-components";
import NavLink from "../../util/NavLink/NavLink";

const NavDrawerFooter = styled.div`
  display: grid;
  gap: 0.5rem;
  margin-top: auto;
`;

const StyledNSWFSLogo = styled.div`
  display: flex;
  gap: 0.25rem;
  align-items: center;
  ${(p) => p.theme.typography.variants.footnote}
  color: ${(p) => p.theme.colors.neutrals.textInverseWeak};
`;

const StyledGovLogo = styled.div`
  display: flex;
  align-items: end;
  justify-content: space-between;
  ${(p) => p.theme.typography.variants.caption}
  color: ${(p) => p.theme.colors.neutrals.textInverse};
`;

const StyledLogos = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 0 0 0.5rem;
  gap: 0.25rem;
`;

const AppNavDrawer = () => {
  return (
    <NavDrawer title="Risk Modelling Platform" data-testid="nav-drawer">
      <NavDrawerMenu>
        <NavLink
          exact
          href="/"
          legacyBehavior
          render={({ isActive }) => (
            <NavDrawerMenuItem isActive={isActive}>
              Modelling projects
            </NavDrawerMenuItem>
          )}
        />
        <NavLink
          href="/settings"
          legacyBehavior
          render={({ isActive }) => (
            <NavDrawerMenuItem isActive={isActive}>Settings</NavDrawerMenuItem>
          )}
        />
      </NavDrawerMenu>
      <NavDrawerFooter>
        <NavDrawerMenu>
          <NavLink
            exact
            href="https://rfs.atlassian.net/wiki/spaces/NR/pages/355631188/Risk+Modelling+Platform+RMP+documentation"
            render={({ isActive }) => (
              <NavDrawerMenuItem isActive={isActive}>Help</NavDrawerMenuItem>
            )}
            target="_blank"
          />
        </NavDrawerMenu>
        <StyledLogos>
          <StyledNSWFSLogo>
            <img height="40" src="/logos/NSW-RFS-logo.svg" alt="NSW RFS logo" />
            NSW RURAL FIRE SERVICE
          </StyledNSWFSLogo>
          <StyledGovLogo>
            <img
              height="24"
              src="/logos/NSW-logo.svg"
              alt="NSW Government logo"
            />
          </StyledGovLogo>
        </StyledLogos>
      </NavDrawerFooter>
    </NavDrawer>
  );
};

export default AppNavDrawer;
