import {
  Field,
  MultiCombobox,
  Search,
  Select,
  TextArea,
  type FormControlOption,
} from "@kablamooss/geo-ds-core-components";
import { Controller, useFormContext } from "react-hook-form";
import styled from "styled-components";
import { useGetBfmcs } from "../../../.rest-hooks/bfmcs";
import { ServiceProjectTypeAttribute } from "../../../.rest-hooks/types";
import deserializeJsonApi from "../../../utils/deserializeJsonApi/deserializeJsonApi";

const projectTypeOptions: FormControlOption<ServiceProjectTypeAttribute>[] = [
  {
    label: "Bush Fire Risk Management Plan (BFRMP)",
    value: ServiceProjectTypeAttribute.bfrmp,
  },
];

export const StyledCreateProjectForm = styled.div`
  display: grid;
  gap: 1rem;
  padding-top: 0.5rem;
`;

export interface CreateProjectFormValues {
  bfmcs: FormControlOption[];
  name: string;
  notes: string;
  type: FormControlOption<ServiceProjectTypeAttribute> | null;
}

export const getDefaultValues = (): CreateProjectFormValues => {
  return {
    bfmcs: [],
    name: "",
    notes: "",
    type: null,
  };
};

const CreateProjectForm = () => {
  const {
    control,
    formState: { errors, isSubmitting },
    register,
  } = useFormContext<CreateProjectFormValues>();

  const {
    data,
    isError: isGetBfmcsError,
    isPending: isGetBfmcsLoading,
  } = useGetBfmcs(
    { perPage: 100 },
    {
      query: {
        select: (response) => deserializeJsonApi(response.data),
      },
    },
  );

  const bfmcs = data?.data || [];
  const bfmcsOptions: FormControlOption[] = bfmcs.map((bfmc) => {
    return {
      value: bfmc.id,
      label: bfmc.name,
    };
  });

  return (
    <StyledCreateProjectForm>
      <Controller
        control={control}
        name="type"
        render={({ field, fieldState }) => (
          <Select
            {...field}
            error={fieldState.error}
            id="type"
            label="Type of project"
            options={projectTypeOptions}
            placeholder="Select"
            validationStatus={fieldState.error && "error"}
          />
        )}
        rules={{
          validate: (value) => !!value || "Type of project is required",
        }}
      />
      <Controller
        control={control}
        name="bfmcs"
        render={({ field, fieldState: { error } }) => {
          return (
            <MultiCombobox
              {...field}
              error={
                isGetBfmcsError
                  ? "Unable to load BFMCs, please try again."
                  : error
              }
              icon={Search}
              isLoading={isGetBfmcsLoading}
              label="BFMC(s)"
              options={bfmcsOptions}
              placeholder="Search"
              data-testid="bfmc"
            />
          );
        }}
        rules={{
          validate: (value) =>
            !!value.length || "At least one BFMC is required",
        }}
      />
      <Field
        error={errors.notes}
        hint="Add any additional details or relevant notes about your project."
        htmlFor="notes"
        label="Notes"
      >
        <TextArea
          data-testid="project-notes"
          disabled={isSubmitting}
          id="notes"
          maxLength={300}
          rows={5}
          {...register("notes")}
        />
      </Field>
    </StyledCreateProjectForm>
  );
};

export default CreateProjectForm;
