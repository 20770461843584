import type { ColumnSort } from "@tanstack/react-table";
import type { GetProjectsSortBy } from "../.rest-hooks/types";

export const projectsSortingRules: Record<GetProjectsSortBy, ColumnSort> = {
  status: {
    id: "status",
    desc: false,
  },
  "-status": {
    id: "status",
    desc: true,
  },
  name: {
    id: "name",
    desc: false,
  },
  "-name": {
    id: "name",
    desc: true,
  },
  createdAt: {
    id: "createdAt",
    desc: false,
  },
  "-createdAt": {
    id: "createdAt",
    desc: true,
  },
};
