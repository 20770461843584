import {
  EndTD,
  ExpandLess,
  ExpandMore,
  IconButton,
  TextLink,
} from "@kablamooss/geo-ds-core-components";
import { createColumnHelper } from "@tanstack/react-table";
import Link from "next/link";
import styled from "styled-components";
import type { Project } from "../../../types";
import FallbackElement from "../../../utils/fallBackElement/fallBackElement";
import { getFormattedDateAndTime } from "../../../utils/formatDate/formatDate";
import ProjectsOverflowMenu from "./ProjectsOverflowMenu";

const StyledTextLink = styled(TextLink)`
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

export type ProjectRow = Pick<
  Project,
  "bfmcs" | "id" | "owner" | "name" | "runs" | "createdAt" | "status"
> & {
  activity: string | undefined;
  lastUpdated: string;
  scenario: string | undefined;
};

const columnHelper = createColumnHelper<ProjectRow>();

export const columns = [
  columnHelper.accessor("name", {
    enableSorting: true,
    header: "Project code",
    size: 160,
    cell: (props) => {
      const projectCode = props.cell.getValue();
      const projectId = props.row.original.id;

      if (!projectCode || !projectId) {
        return <FallbackElement />;
      }

      return (
        <Link href={`/project/${projectId}/scenarios`} legacyBehavior passHref>
          <StyledTextLink>{projectCode}</StyledTextLink>
        </Link>
      );
    },
  }),
  columnHelper.accessor("bfmcs", {
    enableSorting: false,
    header: "BFMC",
    size: 240,
    cell: (props) => {
      const value = props.cell.getValue();
      if (!value) {
        return <FallbackElement />;
      }
      const bfmcNames = value.map((bfmcs) => bfmcs.name);
      return bfmcNames.join(", ");
    },
  }),
  columnHelper.accessor("status", {
    enableSorting: true,
    header: "Project status",
    size: 120,
    cell: (props) => {
      const value = props.cell.getValue();

      if (!value) {
        return <FallbackElement />;
      }

      return value;
    },
  }),
  columnHelper.accessor("createdAt", {
    enableSorting: true,
    header: "Created",
    size: 160,
    cell: (props) => {
      const value = props.cell.getValue();
      if (!value) {
        return <FallbackElement />;
      }
      return getFormattedDateAndTime(value);
    },
  }),
  columnHelper.accessor("scenario", {
    enableSorting: false,
    header: "Scenario",
    size: 120,
    cell: (props) => {
      const value = props.cell.getValue();

      if (!value) {
        return <FallbackElement />;
      }

      return value;
    },
  }),
  columnHelper.accessor("activity", {
    enableSorting: false,
    header: "Activity",
    size: 200,
    cell: (props) => {
      const value = props.cell.getValue();

      if (!value) {
        return <FallbackElement />;
      }

      return value;
    },
  }),
  columnHelper.accessor("lastUpdated", {
    enableSorting: false,
    header: "Last updated",
    size: 200,
    cell: (props) => {
      const value = props.cell.getValue();
      if (!value) {
        return <FallbackElement />;
      }
      return getFormattedDateAndTime(value);
    },
  }),
  columnHelper.display({
    enableSorting: false,
    header: "",
    id: "expander",
    cell: (props) => {
      const isExpanded = props.row.getIsExpanded();

      return (
        <EndTD>
          <ProjectsOverflowMenu />
          <IconButton
            icon={isExpanded ? ExpandLess : ExpandMore}
            label={isExpanded ? "Collapse" : "Expand"}
            onClick={() => props.row.toggleExpanded(!isExpanded)}
            size="sm"
            variant="secondary"
          />
        </EndTD>
      );
    },
  }),
];
