import type { FormControlOption } from "@kablamooss/geo-ds-core-components";
import { AppRunType } from "../.rest-hooks/types";

const STATUSES = ["In progress", "Completed", "Archived"];
type Status = (typeof STATUSES)[number];

export const statusItems: FormControlOption<Status>[] = [
  { label: "In progress", value: "In progress" },
  { label: "Complete", value: "Complete" },
  { label: "Archived", value: "Archived" },
];

export const SCENARIOS = ["CR", "FR", "FT"];
export type Scenario = (typeof SCENARIOS)[number];

export const scenarioItems: FormControlOption<Scenario>[] = [
  { label: "Current Risk", value: "CR" },
  { label: "Future Risk without Treatment", value: "FR" },
  {
    label: "Future Risk with Treatment",
    value: "FT",
  },
];

export const getScenarioLabel = (scenario: Scenario) => {
  return ` ${
    scenarioItems.find((item) => {
      return scenario === item.value;
    })!.label
  } (${scenario})`;
};

export const getScenarioLabelWithoutAcronym = (scenario: Scenario) => {
  return scenarioItems.find((item) => {
    return scenario === item.value;
  })!.label;
};

const runTypesMap = new Map<AppRunType, string>([
  [AppRunType.Phoenix, "Fire Behaviour run"],
  [AppRunType.PhoenixPostprocessing, "Impact Analysis run"],
]);

export const getRunTypeLabel = (runType: AppRunType) => {
  return runTypesMap.get(runType);
};
