import {
  DataTable,
  SubTR,
  type SubRowComponentProps,
} from "@kablamooss/geo-ds-core-components";
import type { ColumnSort } from "@tanstack/react-table";
import { useState } from "react";
import {
  ServiceProjectTypeAttribute,
  type GetProjectsSortBy,
} from "../../../.rest-hooks/types";
import { projectsSortingRules } from "../../../config/projectsSortingRules";
import { useSorting } from "../../../hooks/useSorting";
import type { Project, Run } from "../../../types";
import ProjectsSubDataTable from "./ProjectsSubDataTable";
import { columns, type ProjectRow } from "./columns";

const SubRowComponent = ({
  isExpanded,
  row,
}: SubRowComponentProps<ProjectRow>) => {
  const { id, runs } = row;

  return (
    <SubTR data-testid="project-sub-row" isExpanded={isExpanded}>
      <ProjectsSubDataTable projectId={id} runs={runs as Run[]} />
    </SubTR>
  );
};

interface ProjectsDataTableProps {
  onSortChange: (sortBy?: GetProjectsSortBy) => void;
  page: number;
  perPage: number;
  projects: readonly Project[];
  sortBy: GetProjectsSortBy;
}

const ProjectsDataTable = ({
  onSortChange,
  page,
  perPage,
  projects,
  sortBy,
}: ProjectsDataTableProps) => {
  const [expandedIds, setExpandedIds] = useState<string[]>([]);

  const tableData: ProjectRow[] = projects.map((project) => ({
    activity:
      project.type === ServiceProjectTypeAttribute.bfrmp
        ? project.stageStatus
        : undefined,
    bfmcs: project.bfmcs,
    id: project.id,
    lastUpdated: project.lastActivityDateTime,
    owner: project.owner,
    name: project.name,
    runs: project.runs,
    scenario:
      project.type === ServiceProjectTypeAttribute.bfrmp
        ? project.currentStage
        : undefined,
    createdAt: project.createdAt,
    status: project.status,
  }));

  const sorting = useSorting({
    sortBy,
    sortingRules: projectsSortingRules,
  });

  const onSortingChange = (sort?: ColumnSort) => {
    if (!sort) {
      onSortChange();
      return;
    }

    onSortChange(`${sort.desc ? "-" : ""}${sort.id}` as GetProjectsSortBy);
  };

  return (
    <DataTable
      clickableRows
      columns={columns}
      data={tableData}
      data-testid="projects-table"
      expandedIds={expandedIds}
      getRowId={(row) => row.id}
      onExpandedIdsChange={(ids) => {
        setExpandedIds(ids);
      }}
      onSortingChange={onSortingChange}
      page={page}
      perPage={perPage}
      subRowComponent={SubRowComponent}
      sorting={sorting}
    />
  );
};

export default ProjectsDataTable;
