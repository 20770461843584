import { Text, TypographyStatusIcon } from "@kablamooss/geo-ds-core-components";
import styled, { css } from "styled-components";

export const HEADER_INDICATOR_VARIANTS = ["tenant", "offline"] as const;

export type HeaderIndicatorVariant = (typeof HEADER_INDICATOR_VARIANTS)[number];

const tenantStyles = css`
  background-color: ${(p) => p.theme.colors.informative.backgroundWeak};
  border-bottom: 1px solid ${(p) => p.theme.colors.informative.borderWeak};
`;

const offlineStyles = css`
  background-color: ${(p) => p.theme.colors.warning.backgroundWeak};
  border-bottom: 1px solid ${(p) => p.theme.colors.warning.border};
`;

const headerIndicatorVariantStyles: Record<
  HeaderIndicatorVariant,
  ReturnType<typeof css>
> = {
  tenant: tenantStyles,
  offline: offlineStyles,
} as const;

interface StyledHeaderIndicatorProps {
  variant: HeaderIndicatorVariant;
}

const StyledHeaderIndicator = styled.div<StyledHeaderIndicatorProps>`
  display: flex;
  padding: 0.75rem 1rem calc(0.75rem - 1px) 1rem;
  align-items: center;
  gap: 0.5rem;
  z-index: ${(p) => p.theme.zIndexes.appHeader};
  overflow-x: auto;
  white-space: nowrap;
  ${(p) => headerIndicatorVariantStyles[p.variant]}
`;

type HeaderIndicatorProps = {
  children?: React.ReactNode;
  "data-testid"?: string;
  variant: HeaderIndicatorVariant;
};

const HeaderIndicator = ({
  children,
  "data-testid": dataTestId,
  variant,
}: HeaderIndicatorProps) => {
  return (
    <StyledHeaderIndicator variant={variant} data-testid={dataTestId}>
      <TypographyStatusIcon size="subtitleSm" status="info" />
      <Text
        data-testid={dataTestId && `${dataTestId}-message`}
        size="subtitleSm"
      >
        {children}
      </Text>
    </StyledHeaderIndicator>
  );
};

export default HeaderIndicator;
