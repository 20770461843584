import {
  CopyToClipboard,
  EllipsisVertical,
  IconButton,
  OverflowMenu,
} from "@kablamooss/geo-ds-core-components";

const items = [{ key: "item 1", label: "Item 1", icon: CopyToClipboard }];

const SubProjectsOverflowMenu = () => {
  return (
    <OverflowMenu
      disabled
      items={items}
      onAction={() => {}}
      renderTrigger={(menuProps) => (
        <IconButton
          {...menuProps}
          disabled
          icon={EllipsisVertical}
          label="Actions"
          size="sm"
          variant="secondary"
        />
      )}
    />
  );
};

export default SubProjectsOverflowMenu;
