import { FieldGrid } from "@kablamooss/geo-ds-core-components";
import styled from "styled-components";
import { ValueOrEmDash } from "../../util/ValueOrEmdash";
import type { CreateProjectFormValues } from "./CreateProjectForm";

const StyledCreateProjectFormSummary = styled.div`
  display: grid;
  gap: 1rem;
  padding-top: 0.5rem;
`;

interface CreateProjectFormSummaryProps {
  project: CreateProjectFormValues;
}

const CreateProjectFormSummary = ({
  project,
}: CreateProjectFormSummaryProps) => {
  const { bfmcs, name, notes, type } = project;

  const allBFMCs = bfmcs.map((bfmc) => bfmc.label);

  return (
    <StyledCreateProjectFormSummary>
      <FieldGrid>
        <FieldGrid.Item label="Type of project">{type?.label}</FieldGrid.Item>
        <FieldGrid.Item label="Project code">{name}</FieldGrid.Item>
        <FieldGrid.Item label="BFMC(s)">{allBFMCs.join(", ")}</FieldGrid.Item>
        <FieldGrid.Item label="Notes">
          <ValueOrEmDash value={notes} />
        </FieldGrid.Item>
      </FieldGrid>
    </StyledCreateProjectFormSummary>
  );
};

export default CreateProjectFormSummary;
