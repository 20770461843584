import type { Scenario } from "../../../config/projects";
import type { Run } from "../../../types";
import type { ScenarioRow } from "./subColumns";

const getScenarioRun = (
  scenario: Scenario,
  runs: readonly Run[],
): ScenarioRow | undefined => {
  const scenarioRuns = runs.filter(
    (run) => run.scenario === scenario && run.type === "PhoenixPostprocessing",
  );

  if (!scenarioRuns.length) {
    return;
  }
  const acceptedRun = scenarioRuns.find((run) => run.accepted === true);

  if (!acceptedRun) {
    // Runs are ordered by createdAt, oldest first
    // Reverse and get the newest run
    return scenarioRuns.reverse()[0];
  }

  return acceptedRun;
};

const getProjectScenarioRuns = (runs: readonly Run[]): ScenarioRow[] => {
  const currentRisk = getScenarioRun("CR", runs);
  const futureRisk = getScenarioRun("FR", runs);
  const futureRiskWithTreatment = getScenarioRun("FT", runs);

  return [
    ...(currentRisk ? [currentRisk] : []),
    ...(futureRisk ? [futureRisk] : []),
    ...(futureRiskWithTreatment ? [futureRiskWithTreatment] : []),
  ];
};

export default getProjectScenarioRuns;
