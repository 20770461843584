import {
  Alert,
  ConfirmModal,
  ModalForm,
  onPromise,
  type AlertContent,
  showToast,
} from "@kablamooss/geo-ds-core-components";
import { useQueryClient } from "@tanstack/react-query";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { FormProvider, useForm, type SubmitHandler } from "react-hook-form";
import {
  getGetProjectsQueryKey,
  usePostDryrunProjects,
  usePostProjects,
} from "../../../.rest-hooks/projects";
import {
  ServiceProjectTypeAttribute,
  type ServiceCreateProjectInput,
} from "../../../.rest-hooks/types";
import CreateProjectForm, {
  getDefaultValues,
  type CreateProjectFormValues,
} from "./CreateProjectForm";
import CreateProjectFormSummary from "./CreateProjectFormSummary";

type ViewState =
  | {
      view: "edit";
    }
  | {
      values: CreateProjectFormValues;
      view: "review";
    };

interface CreateProjectModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const CreateProjectModal = ({ isOpen, onClose }: CreateProjectModalProps) => {
  const router = useRouter();
  const queryClient = useQueryClient();

  const [viewState, setViewState] = useState<ViewState>({ view: "edit" });
  const [formError, setFormError] = useState<AlertContent | null>(null);

  const form = useForm<CreateProjectFormValues>({
    defaultValues: getDefaultValues(),
  });

  const {
    formState: { isSubmitting },
    handleSubmit,
    reset,
  } = form;

  useEffect(() => {
    reset(getDefaultValues());
  }, [isOpen, reset]);

  const getSubmitData = (
    values: CreateProjectFormValues,
  ): ServiceCreateProjectInput => {
    const type = values.type?.value;
    switch (type) {
      case ServiceProjectTypeAttribute.bfrmp:
        return {
          bfmcs: values.bfmcs.map((bfmc) => bfmc.value),
          notes: values.notes,
          type,
        };

      case ServiceProjectTypeAttribute["statewide-maxfuels"]:
      case ServiceProjectTypeAttribute["statewide-snapshot"]:
        return {
          notes: values.notes,
          type,
        };

      default:
        throw new Error(`unhandled type: ${type}`);
    }
  };

  const { mutateAsync: dryRun } = usePostDryrunProjects();

  const onSubmit: SubmitHandler<CreateProjectFormValues> = async (values) => {
    setFormError(null);

    try {
      const response = await dryRun({ data: getSubmitData(values) });

      setViewState({
        values: { ...values, name: response.data.data.attributes.name },
        view: "review",
      });
    } catch (error) {
      setFormError({
        title: "Unable to create project",
        message: "Please try again.",
      });
    }
  };

  const { mutateAsync: createProject } = usePostProjects({
    mutation: {
      onSuccess: (data) => {
        const projectId = data.data.data.id;

        void router.push(`/project/${projectId}/project-setup`);

        void queryClient.invalidateQueries({
          queryKey: getGetProjectsQueryKey(),
        });

        showToast({
          title: "Project created",
          variant: "success",
        });
      },
    },
  });

  const onComplete = async (values: CreateProjectFormValues) => {
    await createProject({ data: getSubmitData(values) });
  };

  const onConfirm = () => {
    if (viewState.view !== "review") {
      throw new Error("Cannot call onConfirm outside of review step");
    }

    return onComplete(viewState.values);
  };

  const onBack = () => {
    setViewState({ view: "edit" });
  };

  const onCompleteSuccess = () => {
    setViewState({ view: "edit" });
    onClose();
  };

  const onCancel = () => {
    setFormError(null);
    onClose();
  };

  return (
    <>
      <FormProvider {...form}>
        <ModalForm
          cancelLabel="Cancel"
          error={
            formError && (
              <Alert title={formError.title} variant="error">
                {formError.message}
              </Alert>
            )
          }
          id="create-project"
          isOpen={viewState?.view === "edit" && isOpen}
          isSubmitting={isSubmitting}
          onCancel={onCancel}
          onSubmit={onPromise(handleSubmit(onSubmit))}
          submitLabel="Next"
          title="Add project details"
        >
          <CreateProjectForm />
        </ModalForm>
      </FormProvider>
      <ConfirmModal
        cancelLabel="Back"
        confirmLabel="Create project"
        error={
          <Alert title="Unable to create a new project" variant="error">
            Please review the project and try again.
          </Alert>
        }
        isOpen={viewState.view === "review"}
        onConfirm={onConfirm}
        onConfirmSuccess={onCompleteSuccess}
        onCancel={onBack}
        title="Review project"
      >
        {viewState.view === "review" && (
          <CreateProjectFormSummary project={viewState.values} />
        )}
      </ConfirmModal>
    </>
  );
};

export default CreateProjectModal;
