/**
 * Generated by orval v6.29.1 🍺
 * Do not edit manually.
 * Risk Modelling Platform API
 * The API for the risk modelling platform
 * OpenAPI spec version: 1.0
 */
import {
  useMutation,
  useQuery
} from '@tanstack/react-query'
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult
} from '@tanstack/react-query'
import axios from 'axios'
import type {
  AxiosError,
  AxiosRequestConfig,
  AxiosResponse
} from 'axios'
import qs from 'qs'
import type {
  AppDryRunProjectResponse,
  AppProjectResponse,
  AppProjectsListResponse,
  GetProjectsParams,
  JSONAPIErrorResponse,
  ServiceCreateProjectInput,
  ServiceGenerateCalculatedInput,
  ServicePatchProjectInput
} from './types'




/**
 * Returns a list of all the projects.
 * @summary Get a list of projects
 */
export const getProjects = (
    params?: GetProjectsParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<AppProjectsListResponse>> => {
    
    return axios.get(
      `/projects`,{
    ...options,
        params: {...params, ...options?.params},
        paramsSerializer: (params) => qs.stringify(params, {"arrayFormat":"comma"}),}
    );
  }


export const getGetProjectsQueryKey = (params?: GetProjectsParams,) => {
    return [`/projects`, ...(params ? [params]: [])] as const;
    }

    
export const getGetProjectsQueryOptions = <TData = Awaited<ReturnType<typeof getProjects>>, TError = AxiosError<JSONAPIErrorResponse>>(params?: GetProjectsParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getProjects>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetProjectsQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getProjects>>> = ({ signal }) => getProjects(params, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getProjects>>, TError, TData> & { queryKey: QueryKey }
}

export type GetProjectsQueryResult = NonNullable<Awaited<ReturnType<typeof getProjects>>>
export type GetProjectsQueryError = AxiosError<JSONAPIErrorResponse>

/**
 * @summary Get a list of projects
 */
export const useGetProjects = <TData = Awaited<ReturnType<typeof getProjects>>, TError = AxiosError<JSONAPIErrorResponse>>(
 params?: GetProjectsParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getProjects>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetProjectsQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * Create a new project.
 * @summary Create a new project
 */
export const postProjects = (
    serviceCreateProjectInput: ServiceCreateProjectInput, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<AppProjectResponse>> => {
    
    return axios.post(
      `/projects`,
      serviceCreateProjectInput,options
    );
  }



export const getPostProjectsMutationOptions = <TError = AxiosError<JSONAPIErrorResponse>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postProjects>>, TError,{data: ServiceCreateProjectInput}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof postProjects>>, TError,{data: ServiceCreateProjectInput}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postProjects>>, {data: ServiceCreateProjectInput}> = (props) => {
          const {data} = props ?? {};

          return  postProjects(data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostProjectsMutationResult = NonNullable<Awaited<ReturnType<typeof postProjects>>>
    export type PostProjectsMutationBody = ServiceCreateProjectInput
    export type PostProjectsMutationError = AxiosError<JSONAPIErrorResponse>

    /**
 * @summary Create a new project
 */
export const usePostProjects = <TError = AxiosError<JSONAPIErrorResponse>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postProjects>>, TError,{data: ServiceCreateProjectInput}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof postProjects>>,
        TError,
        {data: ServiceCreateProjectInput},
        TContext
      > => {

      const mutationOptions = getPostProjectsMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Performs a dry-run for project creation. Though identifiers are returned, they should not be considered reserved.
 * @summary Perform a dry-run for project creation
 */
export const postDryrunProjects = (
    serviceCreateProjectInput: ServiceCreateProjectInput, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<AppDryRunProjectResponse>> => {
    
    return axios.post(
      `/dryrun/projects`,
      serviceCreateProjectInput,options
    );
  }



export const getPostDryrunProjectsMutationOptions = <TError = AxiosError<JSONAPIErrorResponse>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postDryrunProjects>>, TError,{data: ServiceCreateProjectInput}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof postDryrunProjects>>, TError,{data: ServiceCreateProjectInput}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postDryrunProjects>>, {data: ServiceCreateProjectInput}> = (props) => {
          const {data} = props ?? {};

          return  postDryrunProjects(data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostDryrunProjectsMutationResult = NonNullable<Awaited<ReturnType<typeof postDryrunProjects>>>
    export type PostDryrunProjectsMutationBody = ServiceCreateProjectInput
    export type PostDryrunProjectsMutationError = AxiosError<JSONAPIErrorResponse>

    /**
 * @summary Perform a dry-run for project creation
 */
export const usePostDryrunProjects = <TError = AxiosError<JSONAPIErrorResponse>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postDryrunProjects>>, TError,{data: ServiceCreateProjectInput}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof postDryrunProjects>>,
        TError,
        {data: ServiceCreateProjectInput},
        TContext
      > => {

      const mutationOptions = getPostDryrunProjectsMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Archive a specific project.
 * @summary Archive a specific project
 */
export const deleteProjectsProjectId = (
    projectId: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<AppProjectResponse>> => {
    
    return axios.delete(
      `/projects/${projectId}`,options
    );
  }



export const getDeleteProjectsProjectIdMutationOptions = <TError = AxiosError<JSONAPIErrorResponse>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteProjectsProjectId>>, TError,{projectId: string}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof deleteProjectsProjectId>>, TError,{projectId: string}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteProjectsProjectId>>, {projectId: string}> = (props) => {
          const {projectId} = props ?? {};

          return  deleteProjectsProjectId(projectId,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteProjectsProjectIdMutationResult = NonNullable<Awaited<ReturnType<typeof deleteProjectsProjectId>>>
    
    export type DeleteProjectsProjectIdMutationError = AxiosError<JSONAPIErrorResponse>

    /**
 * @summary Archive a specific project
 */
export const useDeleteProjectsProjectId = <TError = AxiosError<JSONAPIErrorResponse>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteProjectsProjectId>>, TError,{projectId: string}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof deleteProjectsProjectId>>,
        TError,
        {projectId: string},
        TContext
      > => {

      const mutationOptions = getDeleteProjectsProjectIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Get a specific project.
 * @summary Get a specific project
 */
export const getProjectsProjectId = (
    projectId: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<AppProjectResponse>> => {
    
    return axios.get(
      `/projects/${projectId}`,options
    );
  }


export const getGetProjectsProjectIdQueryKey = (projectId: string,) => {
    return [`/projects/${projectId}`] as const;
    }

    
export const getGetProjectsProjectIdQueryOptions = <TData = Awaited<ReturnType<typeof getProjectsProjectId>>, TError = AxiosError<JSONAPIErrorResponse>>(projectId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getProjectsProjectId>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetProjectsProjectIdQueryKey(projectId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getProjectsProjectId>>> = ({ signal }) => getProjectsProjectId(projectId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(projectId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getProjectsProjectId>>, TError, TData> & { queryKey: QueryKey }
}

export type GetProjectsProjectIdQueryResult = NonNullable<Awaited<ReturnType<typeof getProjectsProjectId>>>
export type GetProjectsProjectIdQueryError = AxiosError<JSONAPIErrorResponse>

/**
 * @summary Get a specific project
 */
export const useGetProjectsProjectId = <TData = Awaited<ReturnType<typeof getProjectsProjectId>>, TError = AxiosError<JSONAPIErrorResponse>>(
 projectId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getProjectsProjectId>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetProjectsProjectIdQueryOptions(projectId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * Updates notes of the project.
 * @summary Patch a projects notes
 */
export const patchProjectsProjectId = (
    projectId: string,
    servicePatchProjectInput: ServicePatchProjectInput, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<AppProjectResponse>> => {
    
    return axios.patch(
      `/projects/${projectId}`,
      servicePatchProjectInput,options
    );
  }



export const getPatchProjectsProjectIdMutationOptions = <TError = AxiosError<JSONAPIErrorResponse>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof patchProjectsProjectId>>, TError,{projectId: string;data: ServicePatchProjectInput}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof patchProjectsProjectId>>, TError,{projectId: string;data: ServicePatchProjectInput}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof patchProjectsProjectId>>, {projectId: string;data: ServicePatchProjectInput}> = (props) => {
          const {projectId,data} = props ?? {};

          return  patchProjectsProjectId(projectId,data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PatchProjectsProjectIdMutationResult = NonNullable<Awaited<ReturnType<typeof patchProjectsProjectId>>>
    export type PatchProjectsProjectIdMutationBody = ServicePatchProjectInput
    export type PatchProjectsProjectIdMutationError = AxiosError<JSONAPIErrorResponse>

    /**
 * @summary Patch a projects notes
 */
export const usePatchProjectsProjectId = <TError = AxiosError<JSONAPIErrorResponse>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof patchProjectsProjectId>>, TError,{projectId: string;data: ServicePatchProjectInput}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof patchProjectsProjectId>>,
        TError,
        {projectId: string;data: ServicePatchProjectInput},
        TContext
      > => {

      const mutationOptions = getPatchProjectsProjectIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Generates a calculated output (change in risk layer).
 * @summary Generate a calculated output
 */
export const postProjectsProjectIdGeneratecalculated = (
    projectId: string,
    serviceGenerateCalculatedInput: ServiceGenerateCalculatedInput, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<void>> => {
    
    return axios.post(
      `/projects/${projectId}/generatecalculated`,
      serviceGenerateCalculatedInput,options
    );
  }



export const getPostProjectsProjectIdGeneratecalculatedMutationOptions = <TError = AxiosError<JSONAPIErrorResponse>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postProjectsProjectIdGeneratecalculated>>, TError,{projectId: string;data: ServiceGenerateCalculatedInput}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof postProjectsProjectIdGeneratecalculated>>, TError,{projectId: string;data: ServiceGenerateCalculatedInput}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postProjectsProjectIdGeneratecalculated>>, {projectId: string;data: ServiceGenerateCalculatedInput}> = (props) => {
          const {projectId,data} = props ?? {};

          return  postProjectsProjectIdGeneratecalculated(projectId,data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostProjectsProjectIdGeneratecalculatedMutationResult = NonNullable<Awaited<ReturnType<typeof postProjectsProjectIdGeneratecalculated>>>
    export type PostProjectsProjectIdGeneratecalculatedMutationBody = ServiceGenerateCalculatedInput
    export type PostProjectsProjectIdGeneratecalculatedMutationError = AxiosError<JSONAPIErrorResponse>

    /**
 * @summary Generate a calculated output
 */
export const usePostProjectsProjectIdGeneratecalculated = <TError = AxiosError<JSONAPIErrorResponse>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postProjectsProjectIdGeneratecalculated>>, TError,{projectId: string;data: ServiceGenerateCalculatedInput}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof postProjectsProjectIdGeneratecalculated>>,
        TError,
        {projectId: string;data: ServiceGenerateCalculatedInput},
        TContext
      > => {

      const mutationOptions = getPostProjectsProjectIdGeneratecalculatedMutationOptions(options);

      return useMutation(mutationOptions);
    }
    