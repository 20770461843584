import {
  Alert,
  DEFAULT_ERROR_MESSAGE,
  DebouncedTextInput,
  Search,
  type PageStateChangeHandler,
} from "@kablamooss/geo-ds-core-components";
import { keepPreviousData } from "@tanstack/react-query";
import { useGetProjects } from "../../../.rest-hooks/projects";
import type { GetProjectsSortBy } from "../../../.rest-hooks/types";
import deserializeJsonApi from "../../../utils/deserializeJsonApi/deserializeJsonApi";
import { SEARCH_RESULTS_TERM_ID } from "../../../utils/pageQuery/pageQuery";
import TableLayout from "../../layout/TableLayout/TableLayout";
import PlaceholderCard from "../../ui/PlaceholderCard/PlaceholderCard";
import ProjectsDataTable from "../ProjectsDataTable/ProjectsDataTable";
import CreateProjectButton from "./CreateProjectButton";
import ProjectsChipList from "./ProjectsChipList";
import {
  defaultProjectSearchQuery,
  getParamsFromProjectSearchQuery,
  type ProjectSearchQuery,
} from "./projectSearchQuery";

interface ProjectsSearchQueryChangeHandlerOptions {
  replace?: boolean;
}

export type ProjectSearchQueryChangeHandler = (
  searchQuery: ProjectSearchQuery,
  options?: ProjectsSearchQueryChangeHandlerOptions,
) => void;

interface ProjectListProps {
  onSearchQueryChange: ProjectSearchQueryChangeHandler;
  searchQuery: ProjectSearchQuery;
}

const ProjectsList = ({
  onSearchQueryChange,
  searchQuery,
}: ProjectListProps) => {
  const params = getParamsFromProjectSearchQuery(searchQuery);

  const query = useGetProjects(
    { ...params },
    {
      query: {
        placeholderData: keepPreviousData,
        select: (response) => deserializeJsonApi(response.data),
      },
    },
  );

  // NOTE: We use the default (no filters) query to derive the `filterOptions` for project owner
  const noFiltersQuery = useGetProjects(
    getParamsFromProjectSearchQuery(defaultProjectSearchQuery),
    {
      query: {
        select: (response) => deserializeJsonApi(response.data),
      },
    },
  );

  const { page, perPage, q } = searchQuery;

  const projects = query.data?.data ?? [];
  const meta = query.data?.meta;

  const onSortChange = (value?: GetProjectsSortBy) => {
    onSearchQueryChange({
      ...searchQuery,
      sortBy: value ?? defaultProjectSearchQuery.sortBy,
    });
  };

  const onTermChange = (value: string) => {
    onSearchQueryChange(
      {
        ...searchQuery,
        q: value,
      },
      { replace: true },
    );
  };

  const onPageStateChange: PageStateChangeHandler = (pageState) => {
    onSearchQueryChange({
      ...searchQuery,
      ...pageState,
    });
  };

  let emptyState: React.ReactNode;
  if (searchQuery.q) {
    emptyState = (
      <PlaceholderCard
        title="No results found"
        subtitle={`No projects matching term "${searchQuery.q}" were found`}
        status="empty"
      />
    );
  } else {
    emptyState = (
      <PlaceholderCard
        title="No projects found"
        subtitle="There are no active projects"
        status="empty"
      />
    );
  }

  return (
    <TableLayout
      actions={<CreateProjectButton />}
      chips={
        <ProjectsChipList
          filterOptions={noFiltersQuery.data?.meta?.filterOptions ?? []}
          onSearchQueryChange={onSearchQueryChange}
          searchQuery={searchQuery}
        />
      }
      emptyState={emptyState}
      errorState={
        <Alert variant="error" title="Unable to load projects">
          {DEFAULT_ERROR_MESSAGE}
        </Alert>
      }
      hasPageIndicator
      isError={query.isError || noFiltersQuery.isError}
      isEmpty={!projects.length}
      isFetching={query.isFetching || noFiltersQuery.isFetching}
      isLoading={query.isPending || noFiltersQuery.isPending}
      meta={meta}
      onPageStateChange={onPageStateChange}
      page={page}
      pageLength={projects.length}
      perPage={perPage}
      search={
        <DebouncedTextInput
          aria-label="Search"
          iconStart={Search}
          id={SEARCH_RESULTS_TERM_ID}
          onChange={onTermChange}
          placeholder="Search by project code or BFMC..."
          size="sm"
          type="search"
          value={q}
        />
      }
      title="BFRMP modelling projects"
    >
      <ProjectsDataTable
        onSortChange={onSortChange}
        page={page}
        perPage={perPage}
        projects={projects}
        sortBy={searchQuery.sortBy}
      />
    </TableLayout>
  );
};

export default ProjectsList;
