import defaults from "lodash/defaults";
import type { ParsedUrlQuery, ParsedUrlQueryInput } from "querystring";
import {
  GetProjectsSortBy,
  type GetProjectsParams,
} from "../../../.rest-hooks/types";
import {
  pluckPageQueryFromQuery,
  type PageQuery,
  getQueryFromPageQuery,
  getParamsFromPageQuery,
  getStringFromQuery,
  getStringArrayFromQuery,
  type ParsedUrlQueryValue,
} from "../../../utils/pageQuery/pageQuery";

const GetProjectsSortByValues = Object.values(GetProjectsSortBy);

export interface ProjectSearchQuery extends Required<PageQuery> {
  owner: string[];
  q: string;
  sortBy: GetProjectsSortBy;
  stage: string[];
  status: string[];
}

export const defaultProjectSearchQuery: ProjectSearchQuery = {
  owner: [],
  page: 1,
  perPage: 10,
  q: "",
  sortBy: GetProjectsSortBy.name,
  stage: [],
  status: [],
} as const;

export const isProjectsSortByOrder = (
  value: string,
): value is GetProjectsSortBy => {
  return GetProjectsSortByValues.includes(value as GetProjectsSortBy);
};

const getProjectsSortByOrderFromQuery = (
  value: ParsedUrlQueryValue,
): GetProjectsSortBy | undefined => {
  const sortByOrder = getStringFromQuery(value);
  if (!sortByOrder) {
    return undefined;
  }

  if (isProjectsSortByOrder(sortByOrder)) {
    return sortByOrder;
  }

  return undefined;
};

export const pluckProjectSearchQueryFromQuery = (
  query: ParsedUrlQuery,
): ProjectSearchQuery => {
  const pageQuery = pluckPageQueryFromQuery(query);

  return defaults<
    Record<string, never>,
    PageQuery,
    Partial<ProjectSearchQuery>,
    ProjectSearchQuery
  >(
    {},
    pageQuery,
    {
      owner: getStringArrayFromQuery(query.owner),
      q: getStringFromQuery(query.q),
      sortBy: getProjectsSortByOrderFromQuery(query.sortBy),
      stage: getStringArrayFromQuery(query.stage),
      status: getStringArrayFromQuery(query.status),
    },
    defaultProjectSearchQuery,
  );
};

export const getParamsFromProjectSearchQuery = ({
  owner,
  page,
  perPage,
  q,
  sortBy,
  stage,
  status,
}: ProjectSearchQuery): GetProjectsParams => {
  const pageParams = getParamsFromPageQuery({ page, perPage });

  return {
    sortBy,
    ...pageParams,
    owner,
    ...(q && { q }),
    stage,
    status,
  };
};

export const getQueryFromProjectSearchQuery = ({
  owner,
  page,
  perPage,
  q,
  sortBy,
  stage,
  status,
}: ProjectSearchQuery): ParsedUrlQueryInput => {
  return {
    ...getQueryFromPageQuery({ page, perPage }),
    ...(owner.length && { owner }),
    ...(q && { q }),
    ...(sortBy && sortBy !== defaultProjectSearchQuery.sortBy && { sortBy }),
    ...(stage.length && { stage }),
    ...(status.length && { status }),
  };
};
