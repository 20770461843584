import { EndTD, StaticIconWrapper } from "@kablamooss/geo-ds-core-components";
import { createColumnHelper } from "@tanstack/react-table";
import styled from "styled-components";
import { getScenarioLabelWithoutAcronym } from "../../../config/projects";
import { Preferred } from "../../../icons";
import type { Run } from "../../../types";
import FallbackElement from "../../../utils/fallBackElement/fallBackElement";
import { getFormattedDateAndTime } from "../../../utils/formatDate/formatDate";
import SubProjectsOverflowMenu from "./SubProjectsOverflowMenu";

const StyledScenarioRun = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

export type ScenarioRow = Pick<
  Run,
  | "accepted"
  | "acceptedBy"
  | "acceptedAt"
  | "completedAt"
  | "description"
  | "id"
  | "name"
  | "scenario"
>;

const columnHelper = createColumnHelper<ScenarioRow>();

export const subColumns = [
  columnHelper.accessor("scenario", {
    enableSorting: false,
    header: "Scenario",
    size: 200,
    cell: (props) => {
      const value = props.cell.getValue();

      if (!value) {
        return <FallbackElement />;
      }

      return getScenarioLabelWithoutAcronym(value);
    },
  }),
  columnHelper.accessor("name", {
    enableSorting: false,
    header: "Activity",
    size: 120,
    cell: (props) => {
      const value = props.cell.getValue();
      const { accepted } = props.row.original;

      if (!value) {
        return <FallbackElement />;
      }

      return (
        <StyledScenarioRun>
          <StaticIconWrapper
            icon={Preferred}
            size="sm"
            variant={accepted ? "primary" : "disabled"}
          />
          {value.toUpperCase()}
        </StyledScenarioRun>
      );
    },
  }),
  columnHelper.accessor("description", {
    enableSorting: false,
    header: "Description",
    size: 200,
    cell: (props) => {
      const value = props.cell.getValue();

      if (!value) {
        return <FallbackElement />;
      }

      return value;
    },
  }),
  columnHelper.accessor("completedAt", {
    enableSorting: false,
    header: "Run completed",
    size: 200,
    cell: (props) => {
      const value = props.cell.getValue();

      if (!value) {
        return <FallbackElement />;
      }
      return getFormattedDateAndTime(value);
    },
  }),
  columnHelper.accessor("acceptedBy", {
    enableSorting: false,
    header: "Accepted by",
    size: 120,
    cell: (props) => {
      const value = props.cell.getValue();
      const acceptedBy = value?.name;

      if (!acceptedBy) {
        return <FallbackElement />;
      }

      return acceptedBy;
    },
  }),
  columnHelper.accessor("acceptedAt", {
    enableSorting: false,
    header: "Accepted at",
    size: 200,
    cell: (props) => {
      const value = props.cell.getValue();

      if (!value) {
        return <FallbackElement />;
      }
      return getFormattedDateAndTime(value);
    },
  }),
  columnHelper.display({
    enableSorting: false,
    header: "",
    id: "actions",
    size: 80,
    cell: () => {
      return (
        <EndTD>
          <SubProjectsOverflowMenu />
        </EndTD>
      );
    },
  }),
];
