import Link, { type LinkProps } from "next/link";
import QueryParams from "../../../config/queryParams";
import { getToUrlObject } from "../../../utils/router/router";

interface TaskLinkProps extends LinkProps {
  children?: React.ReactNode;
}

const TaskLink = ({
  children,
  as,
  href,
  passHref = true,
  ...props
}: TaskLinkProps) => {
  const hrefObject = getToUrlObject(href);

  return (
    <Link
      {...props}
      as={as ?? href}
      href={{
        ...hrefObject,
        query: {
          ...hrefObject.query,
          [QueryParams.HAS_BACK_STACK]: true,
        },
      }}
      passHref={passHref}
    >
      {children}
    </Link>
  );
};

export default TaskLink;
