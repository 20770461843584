import {
  CopyToClipboard,
  EllipsisVertical,
  IconButton,
  OverflowMenu,
} from "@kablamooss/geo-ds-core-components";

const items = [
  { key: "newTab", label: "Open in new tab", icon: CopyToClipboard },
  { key: "copy", label: "Copy link to clipboard", icon: CopyToClipboard },
];

const ProjectsOverflowMenu = () => {
  return (
    <OverflowMenu
      disabled
      items={items}
      onAction={() => {}}
      renderTrigger={(menuProps) => (
        <IconButton
          {...menuProps}
          disabled
          icon={EllipsisVertical}
          label="Actions"
          size="sm"
          variant="secondary"
        />
      )}
    />
  );
};

export default ProjectsOverflowMenu;
