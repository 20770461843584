import { DataTable } from "@kablamooss/geo-ds-core-components";
import styled from "styled-components";
import type { Run } from "../../../types";
import ViewProjectLinkButton from "./ViewProjectLinkButton";
import getProjectScenarioRuns from "./getProjectScenarioRuns";
import { subColumns, type ScenarioRow } from "./subColumns";

const StyledProjectsSubDataTable = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledButton = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 0.75rem;
`;

interface ProjectsSubDataTableProps {
  projectId: string;
  runs: readonly Run[];
}

const ProjectsSubDataTable = ({
  projectId,
  runs,
}: ProjectsSubDataTableProps) => {
  const scenarioRows = getProjectScenarioRuns(runs);

  const tableData: ScenarioRow[] = scenarioRows.map((scenario) => ({
    accepted: scenario.accepted,
    acceptedBy: scenario.acceptedBy,
    acceptedAt: scenario.acceptedAt,
    completedAt: scenario.completedAt,
    description: scenario.description,
    id: scenario.id,
    name: scenario.name,
    scenario: scenario.scenario,
  }));

  return (
    <StyledProjectsSubDataTable>
      <DataTable
        data={tableData}
        data-testid="project-sub-table"
        getRowId={(row) => row.id}
        columns={subColumns}
        page={1}
        perPage={10}
        variant="secondary"
      />
      <StyledButton>
        <ViewProjectLinkButton
          data-testid="projects-sub-table-link-button"
          projectId={projectId}
        />
      </StyledButton>
    </StyledProjectsSubDataTable>
  );
};

export default ProjectsSubDataTable;
