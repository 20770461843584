import { Add, Button } from "@kablamooss/geo-ds-core-components";
import { useBoolean } from "usehooks-ts";
import CreateProjectModal from "./CreateProjectModal";

const CreateProjectButton = () => {
  const { setTrue: open, setFalse: close, value: isOpen } = useBoolean();

  return (
    <>
      <Button
        icon={Add}
        iconPosition="end"
        onClick={open}
        size="md"
        variant="secondary"
        fullWidth
      >
        Create project
      </Button>
      <CreateProjectModal isOpen={isOpen} onClose={close} />
    </>
  );
};

export default CreateProjectButton;
