import {
  CheckboxGroupDropdownChip,
  ChipGroup,
} from "@kablamooss/geo-ds-core-components";
import type { AppFilterOption } from "../../../.rest-hooks/types";
import { statusItems, scenarioItems } from "../../../config/projects";
import type { ProjectSearchQueryChangeHandler } from "./ProjectsList";
import type { ProjectSearchQuery } from "./projectSearchQuery";

interface ProjectsChipListProps {
  filterOptions: readonly AppFilterOption[];
  onSearchQueryChange: ProjectSearchQueryChangeHandler;
  searchQuery: ProjectSearchQuery;
}

const ProjectsChipList = ({
  filterOptions,
  onSearchQueryChange,
  searchQuery,
}: ProjectsChipListProps) => {
  const ownerItems =
    filterOptions.find((filterOption) => filterOption.name === "owner")
      ?.options ?? [];
  return (
    <>
      <ChipGroup>
        <CheckboxGroupDropdownChip
          items={statusItems}
          itemType="status"
          label="Project status"
          onChange={(value) => {
            onSearchQueryChange({
              ...searchQuery,
              status: value,
              page: 1,
            });
          }}
          value={searchQuery.status}
          data-testid="project-status-filter"
        />
      </ChipGroup>
      <ChipGroup>
        <CheckboxGroupDropdownChip
          items={scenarioItems}
          itemType="scenario"
          label="Scenario"
          onChange={(value) => {
            onSearchQueryChange({
              ...searchQuery,
              stage: value,
              page: 1,
            });
          }}
          value={searchQuery.stage}
          data-testid="project-scenario-filter"
        />
      </ChipGroup>
      <ChipGroup>
        <CheckboxGroupDropdownChip
          items={ownerItems}
          itemType="owner"
          label="Owner"
          onChange={(value) => {
            onSearchQueryChange({
              ...searchQuery,
              owner: value,
              page: 1,
            });
          }}
          value={searchQuery.owner}
          data-testid="project-owner-filter"
        />
      </ChipGroup>
    </>
  );
};

export default ProjectsChipList;
